/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import ToastHelper from '@launchpad/util/ToastHelper';
import Api from '@launchpad/logic/api/Api';
import AppButtonModal from '@launchpad/components/modal/AppButtonModal';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import { LPOverridable } from '../../../../../../@launchpad/components';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';
import EditAddressForm from '../../edit/EditAddressForm';
import UserRolesSummary from '../../components/UserRolesSummary';
import UserAdditionalActions from '../../components/UserAdditionalActions';

const VendorSyncButton = ({ user, onTrigger, onSyncDone }) => {
  if (!user?.vendorReference) return null;

  const vendorSync = async () => {
    onTrigger();
    try {
      await new UsersModel().triggerVendorSync(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Vendor sync"
      onConfirm={vendorSync}
      text={`Are you sure you wish to trigger vendor sync for user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

const DeleteUserButton = ({ user, onSyncDone }) => {
  const deleteUser = async () => {
    try {
      await new UsersModel().triggerDeleteUser(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Delete User"
      onConfirm={deleteUser}
      text={`Are you sure you wish to delete user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

const UserResetPasswordButton = ({ user, onTrigger, onSyncDone }) => {
  const userResetPassword = async () => {
    onTrigger();
    try {
      const result = await new UsersModel().triggerResetPasswordForUser(
        user.id
      );
      if (result) {
        ToastHelper.show(
          'You have successfully sent an email to the user to reset the password.',
          'success'
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (onSyncDone) {
        onSyncDone();
      }
    }
  };

  return (
    <DeleteButton
      btnText="Change password"
      onConfirm={userResetPassword}
      text={`Are you sure you wish to change password for user "${user.username}"?`}
      style={{ width: 200 }}
    />
  );
};

const CustomerBasicInfo = ({ user }) => {
  const profile = useSelector(state => state.auth.profile);
  const [directUserRoles, setDirectUserRoles] = useState([]);
  const [otherRoles, setOtherRoles] = useState([]);
  const [rolesIsLoading, setRolesIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const kycStatus = user.kycStatus || '';
  const newKycStatus = kycStatus.replace(/#|_/g, ' ');
  const pannovateAllowed =
    profile &&
    (profile.username.includes('@pannovate.') ||
      profile.username.includes('syllo.io'));

  useEffect(() => {
    if (user.id) {
      loadUserRoles().finally(() => setRolesIsLoading(false));
    }
  }, [user.id]);

  const loadUserRoles = useCallback(async () => {
    const directRolesResponse = await Api.call(
      `admin/user-roles?userId=${user.id}`,
      'GET'
    );

    const otherRolesResponse = await Api.call(
      `admin/user-roles?entityId=${user.id}&entity=USER`,
      'GET'
    );
    setOtherRoles(otherRolesResponse.data);
    setDirectUserRoles(directRolesResponse.data);
  }, [user.id]);

  const handleVendorSync = async () => {
    setIsLoading(true);
    try {
      await new UsersModel().triggerVendorSync(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    setIsLoading(true);
    try {
      await new UsersModel().triggerDeleteUser(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserResetPassword = async () => {
    setIsLoading(true);
    try {
      const result = await new UsersModel().triggerResetPasswordForUser(
        user.id
      );
      if (result) {
        ToastHelper.show(
          'You have successfully sent an email to the user to reset the password.',
          'success'
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddressChange = () => {
    // handle address change logic here
  };

  if (redirect) {
    return <Redirect to={`/user-details/${user.id}`} />;
  }

  return (
    <div className="main" key={user.id}>
      <div className="dark-blur" />
      <div className="customers-info">
        <div className="customer-details">
          <div>
            <h2 className="customer-name">
              <span className="company">
                {user.firstName} {user.lastName}
              </span>
            </h2>
          </div>
        </div>

        {!user?.id || isLoading ? (
          <div style={{ position: 'relative', minHeight: 400 }}>
            <LoadingOverlay />
          </div>
        ) : (
          <div className="customers-main">
            <div className="row" />
            <div className="col">
              <div className="padded">
                <LPOverridable name="user.details.basic.before" user={user} />
                <div className="row text-white">
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    <h4>Basic Info</h4>
                    <hr />
                    <dl>
                      <dt>First name:</dt>
                      <dd>{user.firstName || '-'}</dd>
                      <dt>Last name:</dt>
                      <dd>{user.lastName || '-'}</dd>
                      <dt>Username:</dt>
                      <dd>{user.username || '-'}</dd>
                      <dt>E-mail address:</dt>
                      <dd>{user.email || '-'}</dd>
                      <dt>Customer id:</dt>
                      <dd>{user.id || '-'}</dd>
                      <dt>Birth date:</dt>
                      <dd>{date(user.birthDate, 'D / MMMM / Y') || '-'}</dd>
                      <dt>Onboarding:</dt>
                      <dd>
                        {date(user.registeredAt, 'D / MMMM / Y HH:mm') || '-'}
                      </dd>
                      <dt>Vendor reference:</dt>
                      <dd>{user.vendorReference || '-'}</dd>
                    </dl>

                    {pannovateAllowed && (
                      <div className="mt-2">
                        <VendorSyncButton
                          user={user}
                          onTrigger={handleVendorSync}
                          onSyncDone={() => window.location.reload()}
                        />
                      </div>
                    )}
                    <div className="mt-2">
                      <UserResetPasswordButton
                        user={user}
                        onTrigger={handleUserResetPassword}
                        onSyncDone={() => setIsLoading(false)}
                      />
                    </div>
                    <div className="mt-2">
                      <DeleteUserButton
                        user={user}
                        onSyncDone={() => history.push('/')}
                      />
                    </div>
                    <div className="mt-2">
                      <UserAdditionalActions
                        userId={user.id}
                        directUserRoles={directUserRoles}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    {rolesIsLoading ? (
                      <LoadingOverlay />
                    ) : (
                      <UserRolesSummary
                        directUserRoles={directUserRoles}
                        otherRoles={otherRoles}
                      />
                    )}
                    <h4>KYC</h4>
                    <hr />
                    <dl>
                      <dt>Status:</dt>
                      <dd>{newKycStatus.toUpperCase()}</dd>
                    </dl>
                  </div>
                </div>
                <div className="row text-white">
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Address Info</h4>
                      <AppButtonModal
                        buttonText="Change Address"
                        buttonClassName="button-info"
                        style={{
                          maxWidth: 150
                        }}
                        customContentStyle="remove-overflow"
                      >
                        <EditAddressForm
                          user={user}
                          onConfirm={handleAddressChange}
                        />
                      </AppButtonModal>
                    </div>
                    <hr />
                    <dl>
                      <dt>Address Line One:</dt>
                      <dd>{user.addressOne || '-'}</dd>
                      <dt>Address Line Two:</dt>
                      <dd>{user.addressTwo || '-'}</dd>
                      <dt>City:</dt>
                      <dd>{user.city || '-'}</dd>
                      <dt>Postcode:</dt>
                      <dd>{user.postCode || '-'}</dd>
                      <dt>Country code:</dt>
                      <dd>{user.countryCode || '-'}</dd>
                      <dt>Mobile:</dt>
                      <dd>{user.phoneNumber || '-'}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerBasicInfo;
